import logo from './logo.svg';
import './App.scss';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Button from './Components/Button/Button';
import ColorGame from './Components/ColorGame/ColorGame';
import EggHuntGame from './Components/EggHuntGame/EggHuntGame';
import AppModal from './Components/AppModal';
import Home from './Components/Home/Home';
import HomeButton from './Components/HomeButton/HomeButton';
import Footer from './Components/Footer/Footer';

//context for methods and states
export const AppContext = React.createContext();

function App() {

  // current app that is displayed: can be "Home", "ColorGame", or "EggHuntGame"
  const [currentApp, setCurrentApp] = useState('Home');

  // method that changes the current app
  const mainContainerRef = useRef();
  const changeCurrentApp = (newApp) => {
    mainContainerRef.current.style.marginTop= "100px";
    mainContainerRef.current.style.pointerEvents= "none";
    mainContainerRef.current.style.opacity= "0";
    setTimeout(()=>{
      setCurrentApp(newApp);
      mainContainerRef.current.style = {opacity: 1}
    },400)    
  }

  // modal state - contains the visibility status and content of the modal 
  const [modal, setModal] = useState({
    visible: false,
    content: (<div></div>)
  })


  // animations
  const AnimateAttention = (element) => {
    element.current.classList.add("animate-attention")
    setTimeout(()=>{
      element.current.classList.remove("animate-attention")
    }, 300 )
  }
  // adds an error class to a provided element
  const AddErrorClass = (element) => {
    element.current.classList.add("error-text")
  }
  

  // Show modal method
  const ShowModal = (content) => {
    setModal(prev=>{return({visible: true, content: content})});
  }
  // Close modal method
  const CloseModal = () => {
    setModal(prev=>{return({visible: false, content: (<div></div>)})});
    console.log('closing modal')
  }


  const methodsForContext={
    changeCurrentApp: changeCurrentApp,
    AnimateAttention: AnimateAttention,
    AddErrorClass: AddErrorClass,
    ShowModal: ShowModal,
    CloseModal: CloseModal
  }

  // automatically change route based on current app
  const navigate = useNavigate();
  useEffect(()=>{    

    switch(currentApp){
      case "Home":
        navigate("/");
        break;
      case "ColorGame":
        navigate("coloreazacucss");
        break;
      case "EggHuntGame":
        navigate("gasestepuii");
        break;
      default:
        navigate("/");
    }

  }, [currentApp])


  

  return (    
    <div className="App">
        <AppContext.Provider value={methodsForContext}>
          {modal.visible?(<AppModal content={modal.content}></AppModal>):""}
          {/* <Header></Header> */}
          <div className='main-container' ref={mainContainerRef}>
            {currentApp!="Home"?<HomeButton></HomeButton>:""}
            <Routes>
              <Route path="/" element={<Home></Home>}></Route>
              <Route path="/coloreazacucss" element={<ColorGame></ColorGame>}></Route>
              <Route path="/gasestepuii" element={<EggHuntGame></EggHuntGame>}></Route>
            </Routes>
          </div>
          <Footer></Footer>
        </AppContext.Provider>
    </div>
  );
}

export default App;
