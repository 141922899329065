import "./ColorGameRules.scss";
import ColorGameReguli from "./../../images/colorgame_rules.png";


const ColorGameRules = (props) => {
    return (
        <>
        <div className="color-game-reguli">
            <img src={ColorGameReguli}/>
        </div>
        </>
    )
}

export default ColorGameRules;