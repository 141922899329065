import './Footer.scss';

const Footer = (props) => {

    return (
        <>
            <footer>
                <small>&copy;2024 Softia</small>
            </footer>
        </>
    )
}

export default Footer;