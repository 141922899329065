import React, { useContext, useEffect, useRef, useState } from "react";
import Bunny from "./../../images/bunny.png";
import "./EggHuntGame.scss";

import { AppContext } from "../../App";
import EggHuntRules from "./EggHuntRules";
import Button from "../Button/Button";


const EggHuntGame = (props) => {


    const methods = useContext(AppContext);

    // justify-content and align-items states
    const [justifyValue, setJustifyValue] = useState('start')
    const [alignValue, setAlignValue] = useState('start');


    // justify-content and align-items states in romanian for use in the UI
    const [justifyRo, setJustifyRo] = useState('la stanga');
    const [alignRo, setAlignRo] = useState('sus');

    // winning squares number
    const [winSq, setWinSq] = useState(0);

    // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY
    // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY
    // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY
    // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY
    // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY  // MOVING THE BUNNY


    // Will use position: absolute instead of flex
    // Explanation:
    // justify-content and align-items don't work with transitions => can't do animations
    // code will simulate justify-content and align-items using position: absolut; to be able to do animations    
    const [bunnyPositionStyle, setBunnyPositionStyle] = useState({
        top: "0%",
        left: "0%",
    })


    //converts the values (center, start, end) to percentages for top and left
    const ConvertValueToPosition = (val) => {
        switch (val) {
            case "start":
                return "0%"
                break;
            case "center":
                return "33%"
                break;
            case "end":
                return "66%"
                break;
            default:
                console.log("ERR at ConvertValueToPosition. No such case");
        }
    }




    // sets bunnyPositionStyle state everytime justifyValue or alignValue changes
    // also changes the RO value states for the UI
    useEffect(()=>{
        //moves the bunny in the ui
        setBunnyPositionStyle(()=>{return {
            top: ConvertValueToPosition(alignValue), 
            left: ConvertValueToPosition(justifyValue) 
        }})
        setJustifyRo(()=>{return ConvertValueToRo(justifyValue, "justify")});
        setAlignRo(()=>{return ConvertValueToRo(alignValue, "align")});
    },[justifyValue, alignValue])


    // change states for justifyValue and alignValue
    const ChangeValues = (inputValue, changeWhat) =>{

        //sets the justify and align value
        if(inputValue=="start" || inputValue=="center" || inputValue=="end") {
            changeWhat=="justify"?setJustifyValue(inputValue):setAlignValue(inputValue);
        }
    }

    // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID 
    // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID 
    // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID 
    // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID   // GENERATING THE GRID


    //state for the properties from which the elements will be crated
    const [gridProperties, setGridProperties] = useState([]);
    //state for the elements created from the properties
    const [gridElements, setGridElements] = useState([]);


    //GENERATE DEFAULT GRID PROPERTIES
    useEffect(()=>{

        //generating random number of winning squares (between 4 and 5)
        const maxWinCount = Math.floor(Math.random() * 2)+4;
        // set the new winning square state
        setWinSq(maxWinCount);

        // creating an array of winning Ids based on the number of winning sqares
        const winIds = [];
        let winCount = 0;
        while(winCount < maxWinCount){
            const newId = Math.floor(Math.random() * 9)+1;
            if(winIds!=null && newId!=1 && !winIds.includes(newId)){
                winIds.push(newId);
                winCount++;
            }
        }

        console.log(`winning ids are: ${winIds}`);

        // create starting grid properties
        const newGridProperties = [];
        for(let i=0; i<9 ; i++) {
            newGridProperties.push({
                itemId: i+1,
                win: winIds.includes(i+1)?true:false,
                empty:  i==0?true:false,
                found: false,
            })
        }
        setGridProperties(newGridProperties);

    },[])
    




    // GENERATES GRID ELEMENTS when properties change
    useEffect(()=>{
        
        const newGridElements = gridProperties.map(property => {
            // create the className for the current element. 
            // "Empty" when bunny hasn't found anything on the current square 
            // "Found" when bunny has found treasure on the square
            let itemClassList = "egg-hunt-grid-item";
            if(property.empty){
                itemClassList+=" empty";
            } else if(property.found) {
                itemClassList+=" found";
            }


            return(<div className={itemClassList} key={'gridItem'+property.itemId}><span>{property.itemId}</span></div>)
        });        

        // change state of grid elements to the new elements
        setGridElements(newGridElements);


        //change remaining winning squares number
        if(winSq!=0){
            let newFoundSq = 0;
            let newWinSq = 0;
            gridProperties.forEach(gridProperty => {
                if(gridProperty.found){
                    newFoundSq++;
                } 
                if(gridProperty.win){
                    newWinSq++;
                }
            });

            console.log(`-------------`)
            console.log(`There are ${newWinSq} chicks`)
            console.log(`Found ${newFoundSq} chicks`)
            console.log(`${newWinSq-newFoundSq} chicks remaining`)
            setWinSq(newWinSq-newFoundSq);
        }

        
    },[gridProperties])



    // checks if grid item has win property when landed on
    const ItemHasWin = (newGridProperties, index) => {
        if(newGridProperties[index].win){
            return(true);
        } else {
            return(false);
        }   
    }

    // CHANGE PROPERTIES WHEN LANDING ON A SQUARE
    useEffect(()=>{

        if(gridProperties.length){
        const currentPosition = `${justifyValue}-${alignValue}`;

        
            //clone grid properties
            const newGridProperties = JSON.parse(JSON.stringify(gridProperties));

            // index of the item to search 
            let itemToSearch = 0;

            switch (currentPosition) {
                case ('center-start'):
                    itemToSearch=1;
                    break;
                case ('end-start'):
                    itemToSearch=2;
                    break;
                case ('start-center'):
                    itemToSearch=3;
                    break;
                case ('center-center'):
                    itemToSearch=4;
                    break;
                case ('end-center'):
                    itemToSearch=5;
                    break;
                case ('start-end'):
                    itemToSearch=6;
                    break;
                case ('center-end'):
                    itemToSearch=7;
                    break;
                case ('end-end'):
                    itemToSearch=8;
                    break;
                default: 
                    itemToSearch=0;
            }

            if(!newGridProperties[itemToSearch].found && !newGridProperties[itemToSearch].empty){
                ItemHasWin(newGridProperties, itemToSearch)? newGridProperties[itemToSearch].found=true: newGridProperties[itemToSearch].empty=true;
            }
            console.log(newGridProperties);
            setGridProperties(newGridProperties);
        }

        
    }, [justifyValue, alignValue])

    

    // CHANGING THE RO UI positions   // CHANGING THE RO UI positions   // CHANGING THE RO UI positions   // CHANGING THE RO UI positions 
    // CHANGING THE RO UI positions   // CHANGING THE RO UI positions   // CHANGING THE RO UI positions   // CHANGING THE RO UI positions 
    // CHANGING THE RO UI positions   // CHANGING THE RO UI positions   // CHANGING THE RO UI positions   // CHANGING THE RO UI positions 

    //converts the values (center, start, end) to Ro text and sets 
    const ConvertValueToRo = (val, changeWhat) =>{
        switch (val) {
            case "start": {
                changeWhat=="justify"?setJustifyRo("la stânga"):setAlignRo("sus");
                break;
            }
            case "center": {
                changeWhat=="justify"?setJustifyRo("centrat"):setAlignRo("centrat");
                break;
            }
            case "end": {
                changeWhat=="justify"?setJustifyRo("la dreapta"):setAlignRo("jos");
                break;
            }
            default:
                console.log("ERR at ConvertValueToRo. No such case")
        }
    }

    const ShowEggHuntRules = () =>{
        methods.ShowModal(<EggHuntRules></EggHuntRules>);
    }


    return(
        
        <div className="app-game game egg-hunt-game">
            <div className="h_bg">
                <h2>Găsește Puii</h2>
            </div>

            <div className="egg-hunt-game-description">
                <br></br>
                <p>Ajută iepurașul să găsească <strong>puișorii din ouă</strong> folosind <strong>comenzi de CSS</strong>.</p>
                <h3><strong>Cum functionează?</strong></h3>
                <Button buttonName="Vezi aici" buttonMethod={ShowEggHuntRules}></Button>

            </div>

            <div className="game-container">
                <div className="egg-hunt-container">
                    <div className="egg-hunt-display">
                        {gridElements}
                        <img src={Bunny} style={bunnyPositionStyle} className="egg-hunt-bunny"/>
                    </div>
                </div>


                <div className="code-container">
                    <p><strong>Scrie în căsuțe câte una din valorile: "start", "center" sau "end":</strong></p>
                    <code>
                        <span className="code-class">.iepuras</span> <span className="brackets">&#123;</span> 
                        <br/>
                        &nbsp;&nbsp;justify-content: <input type="text" onChange={(e)=>ChangeValues(e.target.value, "justify")} defaultValue={"start"}/>;
                        <br></br>
                        &nbsp;&nbsp;align-items: <input type="text" onChange={(e)=>ChangeValues(e.target.value, "align")} defaultValue={"start"}/>;
                        <br/>
                        <span className="brackets">&#10101;</span>
                    </code>
                    {winSq>0?(<p>Mai sunt <strong>{winSq}</strong> pui de găsit</p>):(<h3><strong>🥳 Felicitări!!! Ai găsit toți puii! 🥳</strong></h3>)}
                    
                    <p>Momentan, iepurașul este aliniat:</p>
                    <ul className="bunny-position-ui">
                        <li><strong>{justifyRo}</strong> pe orizontală</li>
                        <li><strong>{alignRo}</strong> pe verticală</li>
                    </ul>
                </div>
            </div>
        </div>
        
    )
}

export default EggHuntGame;