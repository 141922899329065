


const CodeFata = (props) => {

    const ChangeColor = props.ChangeColor;

    return(
        <>

        <code className="code-box code-fata">
            <span className="code-class">.par-fata</span> <span className="brackets">&#123;</span> 
            <br/>
            &nbsp;&nbsp;color: #<input type="text" onChange={(event)=>{ChangeColor('par-fata', event.target.value)}}></input>;
            <br/>
            <span className="brackets">&#10101;</span>
            <br/>
            <br/>

            <span className="code-class">.tricou-fata</span> <span className="brackets">&#123;</span> 
            <br/>
            &nbsp;&nbsp;color: #<input type="text" onChange={(event)=>{ChangeColor('tricou-fata', event.target.value)}}></input>;
            <br/>
            <span className="brackets">&#10101;</span>
            <br/>
            <br/>

            <span className="code-class">.fusta-fata</span> <span className="brackets">&#123;</span> 
            <br/>
            &nbsp;&nbsp;color: #<input type="text" onChange={(event)=>{ChangeColor('fusta-fata', event.target.value)}}></input>;
            <br/>
            <span className="brackets">&#10101;</span>
            <br/>
        </code>
        </>
    )
}

export default CodeFata;