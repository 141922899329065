import { useCallback, useContext, useRef, useState } from "react";
import './ColorGame.scss';

import CodeBaiat from "./CodeBaiat";
import CodeFata from "./CodeFata";

import { AppContext } from "../../App";
import Copii from "./Copii";
import Button from "../Button/Button";
import ColorGameRules from "./ColorGameRules";

const ColorGame = (props) => {

    const methods = useContext(AppContext);

    //properties of boy colors
    const [parBaiat, setParBaiat] = useState('#8A4B39');
    const [tricouBaiat, setTricouBaiat] = useState('#4CA55F');
    const [pantaloniBaiat, setPantaloniBaiat] = useState('#76c0cc');
    //properties of girl colors
    const [parFata, setParFata] = useState('#F48020');
    const [tricouFata, setTricouFata] = useState('#FFB0BF');
    const [fustaFata, setFustaFatat] = useState('#EF4B23');


    //info displayed in .color-game-info, the errRef paragraph below the code
    const [indicatiiColorGame, setIndicatiiColorGame] = useState((<p>Scrie <strong>coduri</strong> în căsuțe pentru a <strong>colora personajele :D</strong></p>))
    const errRef = useRef();
    const ResetErr = () => {
        setIndicatiiColorGame((<p>Scrie <strong>coduri</strong> în căsuțe pentru a <strong>colora personajele :D</strong></p>));
    }


    // reference to Copii illustration
    const copiiRef = useRef();

    // props containing color info to send to Copii.js
    const baiatInfo = {
        parBaiat: parBaiat,
        tricouBaiat: tricouBaiat,
        pantaloniBaiat: pantaloniBaiat
    }
    const fataInfo = {
        parFata: parFata,
        tricouFata: tricouFata,
        fustaFata: fustaFata
    }


    // tests the color string if it is a hex color
    const CheckColor = (colorString) => {
        const hexRegex = /^#[0-9A-Fa-f]{6}$/;
        return hexRegex.test(`#${colorString}`);
    }

    // change color function - on every input field change
    const ChangeColor = (changeWhat, colorString) => {

        // executes only if there are 6 or more characters
        if(colorString.length==6) {
            //checks if color is valid and  
            if(CheckColor(colorString)){
                switch (changeWhat) {
                    case 'par-baiat':
                        setParBaiat(`#${colorString}`);
                        break;
                    case 'tricou-baiat':
                        setTricouBaiat(`#${colorString}`);
                        break;
                    case 'pantaloni-baiat':
                        setPantaloniBaiat(`#${colorString}`);
                        break;            
                    case 'par-fata':
                        setParFata(`#${colorString}`);
                        break;
                    case 'tricou-fata':
                        setTricouFata(`#${colorString}`);
                        break;   
                    case 'fusta-fata':
                        setFustaFatat(`#${colorString}`);
                        break;
                    default:
                        console.log('error at changeColor switch. No such case')
                }
                methods.AnimateAttention(errRef);
                methods.AnimateAttention(copiiRef);
                errRef.current.classList.remove('error-text');
                setIndicatiiColorGame((
                    <p>Felicitări! 🥳 Ai găsit culoarea #{colorString} <span className="patratel-culoare" style={{backgroundColor: `#${colorString}`}}></span> pentru <strong><em>{changeWhat}</em></strong></p>
                ))
            } else {
                methods.AnimateAttention(errRef);
                methods.AddErrorClass(errRef);
                setIndicatiiColorGame((<p>Caracterele culorii pentru <strong><em>{changeWhat}</em></strong> nu sunt corecte</p>))

            }
        } else if (colorString.length>6) {
            methods.AnimateAttention(errRef);
            methods.AddErrorClass(errRef);
            setIndicatiiColorGame((<p>Ai prea multe caractere pentru <strong><em>{changeWhat}</em></strong></p>))
        } else {
            //reset error/info box 
            ResetErr();
            errRef.current.classList.remove('error-text');
        }
    }


    // method to open modal with color game rules
    const ShowColorGameRules = () =>{
        methods.ShowModal(<ColorGameRules></ColorGameRules>);
    }



    return(
        <div className="app-game color-game">
            <div className="h_bg">
                <h2>Colorează cu CSS</h2>
            </div>
            
            <div className="color-game-description">
                <p>Culorile au <strong>6 caractere</strong> iar fiecare caracter poate fi:</p>
                <ul>
                    <li>de la <strong>0</strong> la <strong>9</strong></li>
                    <li>de la <strong>A</strong> la <strong>F</strong></li>
                </ul>
            </div>
           
            <div className="game-container">
                <div className="ilustratie_color_game" ref={copiiRef}>
                    <Copii baiatInfo={baiatInfo} fataInfo={fataInfo}></Copii>
                </div>
                
                <div className="color-game-info" ref={errRef}>
                    {indicatiiColorGame}
                </div>


                <CodeBaiat ChangeColor={ChangeColor}></CodeBaiat> 
                <CodeFata ChangeColor={ChangeColor}></CodeFata>
                
            </div>

            <div className="color-game-extra-info">
                <h3><strong>Vrei sa afli mai multe?</strong></h3>
                <Button buttonName="Vezi aici" buttonMethod={ShowColorGameRules}></Button>
            </div>
            

        </div>
    )
}

export default ColorGame;