import './HomeButton.scss';

import { useContext } from "react"
import { AppContext } from "../../App"



const HomeButton = (props) => {
    
    const methods = useContext(AppContext)
    
    return(
        <>
            <button className="back-button" onClick={()=>methods.changeCurrentApp("Home")}>Inapoi</button>
        </>
    )
}

export default HomeButton;