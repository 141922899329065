import './Button.scss';

const Button = (props) => {

    const buttonName = props.buttonName;
    const buttonMethod = props.buttonMethod;

    return(
        <button onClick={()=>buttonMethod()} className='app-button'>{buttonName}</button>
    )
}

export default Button;