import { useContext } from "react";
import "./AppModal.scss";
import { AppContext } from "../App";

const AppModal = (props) => {
    const content = props.content;

    const methods = useContext(AppContext);

    return(
        <>  

            <div className="app-modal">
                <div className="black-overlay" onClick={()=>methods.CloseModal()}></div>
                <div className="close-modal" onClick={()=>methods.CloseModal()}>x</div>
                <div className="app-modal-content">
                    {content}
                </div>                
            </div>
        </>
    )
}

export default AppModal;