
import './Home.scss';
import colorImage from './../../images/copii.svg'
import eggHuntImage from './../../images/bunny.png'
import { useContext } from 'react';
import { AppContext } from '../../App';

const Home = (porps) => {

    const methods = useContext(AppContext);


    return(
        <div className='app-game'>
            <div className="h_bg">
                <h2>Alege Jocul</h2>
            </div>
            <div className='home-buttons'>
                <div className='color-game-button game-button' onClick={()=>methods.changeCurrentApp('ColorGame')}>
                    <h3>Colorează cu CSS</h3>
                </div>
                <div className='egg-hunt-game-button game-button' onClick={()=>methods.changeCurrentApp('EggHuntGame')}>
                    <h3>Găsește Puii</h3>
                </div>
            </div>
        </div>
    )
}

export default Home;