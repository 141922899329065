import "./EggHuntRules.scss";
import EggHuntPasi from "./../../images/egghunt_pasi.png";

const EggHuntRules = () => {
    return(
        <>
        <div className="egg-hunt-reguli">
            <img src={EggHuntPasi}/>
        </div>
            {/* <div className="egg-hunt-reguli">
                <div className="egg-hunt-regula">

                    <p><strong>Regula 1</strong>: Iepurasul se plimba pe <strong>orizontala</strong> sau <strong>verticala</strong> in functie de casuta in care scrii </p>
                    <ul>
                        <li><strong>justify-content</strong> muta iepurasul <strong>pe orizontala</strong></li>
                        <li><strong>align-items</strong> muta iepurasul <strong>pe verticala</strong></li>
                    </ul>                    
                </div>

                <hr></hr>

                <div className="egg-hunt-regula">
                <p><strong>Regula 2</strong>: In casutele de mai jos poti scrie <strong>start</strong>, <strong>center</strong> sau <strong>end</strong>:</p>
                    
                    <ul>
                        <li><strong>start</strong> = <strong>inceputul</strong> randului sau coloanei</li>
                        <li><strong>center</strong> = <strong>centrul</strong> randului sau coloanei</li>
                        <li><strong>end</strong> = <strong>capatul</strong> randului sau coloanei</li>
                    </ul>
                </div>

                <hr></hr>

                <div className="egg-hunt-regula">
                    <p><strong>Exemplu:</strong></p>
                    
                    <ul>
                        <li><strong>justify-content</strong>: <strong>end</strong></li>
                        <li><strong>align-items</strong>: <strong>center</strong></li>
                    </ul>
                    <p>Iepurasul se va aseza <strong>pe orizontala in capat</strong> (ultima coloana) iar <strong>pe verticala in centru</strong> (randul din mijloc)</p>

                </div>
            </div> */}
        </>
    )
}

export default EggHuntRules;