import "./Copii.scss";

const Copii = (props) => {

    const fataInfo = props.fataInfo;
    const baiatInfo = props.baiatInfo


    //styles for baiat
    const parBaiatStyle = {
        fill: baiatInfo.parBaiat
    }
    const tricouBaiatStyle = {
        fill: baiatInfo.tricouBaiat
    }
    const pantaloniBaiatStyle = {
        fill: baiatInfo.pantaloniBaiat
    }

    //styles for fata
    const parFataStyle = {
        fill: fataInfo.parFata
    }
    const tricouFataStyle = {
        fill: fataInfo.tricouFata
    }
    const fustaFataStyle = {
        fill: fataInfo.fustaFata
    }


    return(

        <>
        

        <svg id="drawing" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 669.8 517.4">


        <g id="kids">
    <g>
      <g>
        <polygon class="cls-18" points="221 444 203.3 479.4 192.9 473.2 210 433.6 221 444"/>
        <polygon class="cls-18" points="130.6 444.7 124.9 487.2 137.1 488.1 145.7 442.1 130.6 444.7"/>
        <g>
          <g>
            <path class="cls-13" d="M192.2,481.2c-2.9,1.2-5.6,6.3-3.3,8.9,5,4.2,22.6,13.4,28.7,14.6,6.3,1.2,5.2-11,5.2-11l-30.6-12.5h0Z"/>
            <path class="cls-22" d="M193.1,471.2c-1.7,2.3-6.2,9.3-3.8,12.5,5.8,3.2,20.4,12.3,29.1,15.3,9.8,1.4,3.9-9.8-.7-13.3-4.5-3.5-12.9-12.7-12.9-12.7,0,0-5.4.1-11.7-1.8h0Z"/>
          </g>
          <path class="cls-13" d="M211.4,478.1c-2.6-1.4-6.8-.9-8.6.7-1.8,1.7,1.2,2.7,2.4,2.9,1.2.2,3.2.1,7.2.5,3.9.4,1.1-2.9-1-4.1h0Z"/>
          <path class="cls-13" d="M217.6,483.8c-2.6-1.4-6.8-.9-8.6.7-1.8,1.7.8,2.4,2,2.6s3.6.5,7.6.8c3.9.4,1.1-2.9-1-4.1Z"/>
        </g>
        <g>
          <g>
            <path class="cls-13" d="M136,496.8c1.7,2.3,2,8.1-.6,9.5-5.1,1.7-25.5,2.5-30.4,1-5.1-1.6,0-12.2,0-12.2l31.1,1.7h0Z"/>
            <path class="cls-22" d="M137.1,486.5c.5,2.9,3,12,.1,13.9-5.4.4-23.5,2.5-30.9,1.5-7.8-2.9.5-10.5,5.1-11.7,4.5-1.2,11.4-5.9,11.4-5.9,0,0,9,1.2,14.3,2.2Z"/>
          </g>
          <path class="cls-13" d="M118.7,485.9c2.4-.2,5.4,2.1,6.1,4.3.7,2.3-1.8,2-2.8,1.6-.9-.4-2.4-1.3-5.5-2.6-3-1.4.3-3.2,2.2-3.3Z"/>
          <path class="cls-13" d="M112.2,488.4c2.4-.2,5.4,2.1,6.1,4.3.7,2.3-1.4,1.8-2.4,1.5-.9-.4-2.8-1.1-5.9-2.5-3-1.4.2-3.1,2.2-3.3Z"/>
        </g>
      </g>
      <path class="cls-18" d="M237.2,293.2c43.2,16.7,61,0,61,0,0,0-1.4-9.9,1.8-10.3s3.3,5,3.3,5c0,0,6.8-5.2,9.2-7.7,3.2-3.1,5.6-.7,3.3,2.4-1.3,2-3.9,5-3.9,5.2.3-.1,4.6-3.3,7.1-4.9,3.6-2.5,5.6,1.4,2.5,3.6-1.1.8-6.7,4.9-6.9,5.1.3,0,5.5-2.7,7.9-3.6,2.8-1.1,4.1,1.9,1.2,3.7-1.2.9-7.2,4-7.4,4.1,1.3-.4,6.4-2.4,7.3-.6,1,1.7-2,3.5-6.7,4.9-5.4,3.7-11,4.8-14.7,2.7-33.8,19.8-69.8,2.7-69.8,2.7,0,0,4.3-7.8,4.8-12.3h0Z"/>
      <g>
        <path class="cls-18" d="M156.5,277.1s10.1-3.4,17-5.7c.4-7.1-1.3-14.9-1.3-14.9l12.9-1.8s1.4,11.5,1.4,16.1c9.5,2.1,16.8,3.7,16.8,3.7,0,0,12.9,29.5-20,29.4-8.6,0-27.8-13.4-26.8-26.8h0Z"/>
        <path class="cls-6" d="M175.1,266v7.6c-.1,0,4.9-5.4,10.4-.4-.8-7.8-1.2-9.7-1.2-9.7l-9.2,2.5Z"/>
      </g>
      <g>
        <path class="cls-14" d="M197.6,272.2c1,4.6,1,12.6-12.2,14.2-12.7,1.6-20.6-7.5-23-12.4-3.6,1.2-8.1,2.2-8.1,2.2,0,0,11.2,19.4,33.5,17.1,20.9-2.2,18.2-19.6,18.2-19.6l-8.4-1.5h0Z"/>
        <path class="cls-14" d="M246.8,293.1s-6.9,16.3-16.4,22.2c-2.9-1.9-6.7-5.1-6.7-5.1,0,0,9.2-8.6,15.8-21.7,4.2,3,7.3,4.6,7.3,4.6h0Z"/>
        <path class="cls-14" d="M128.6,315.3s6.9-21,7.4-34.8c-3.4-1.5-9.4-2.2-9.4-2.2.2,6.3-1.6,20.3-8.8,35.6,4.3,1.5,10.8,1.4,10.8,1.4h0Z"/>
      </g>
      <path class="cls-18" d="M125.4,298s-39.6-4.5-57.9-39.1c-4.3-.1-8.5-4-11.1-10-3.2-3.7-4.8-6.8-3.1-7.7,1.7-1,5,3.4,5.9,4.4,0-.2-3.5-6-4.1-7.4-1.5-3.1,1.2-4.9,3-2.5,1.5,2,4.6,7,4.8,7.2,0-.3-2.7-6.8-3.2-8-1.5-3.5,2.2-5.7,4-1.7,1.2,2.7,3.2,7.6,3.4,7.9,0-.2-.5-4.1-.6-6.5-.3-3.9,3-4.6,4.1-.3.8,3.4,3.8,11.4,3.8,11.4,0,0,2.9-4.5,5.4-2.5s-3.9,9.7-3.9,9.7c0,0,6.4,23.6,51.9,32.1-1.9,4.1-2.4,13-2.4,13h0Z"/>
      <g>
        <path class="cls-18" d="M107.2,218.3c8.8,36.8,43.3,54.6,78.8,46.2s58.5-39.7,49.8-76.7c-8.8-36.8-44.3-58.6-79.7-50.2-35.6,8.4-57.6,43.7-48.9,80.7Z"/>
        <g>
          <path class="cls-18" d="M109.6,218s-2.6-7.3-12-6.4c-9.8.9-11,14.3-4.3,20.8,6.8,6.4,17.4,4.3,19.6-2.3,0,0-3.3-12.1-3.3-12.1Z"/>
          <path class="cls-4" d="M96.4,215.2c6.1-2.3,13.3,7.7,6,15.1,2.1-8.5-1.8-10.6-5.5-5.9-.5-2.5.8-4.4,4.6-5-.9-3.3-5.1-4.2-5.1-4.2h0Z"/>
        </g>
        <path class="cls-21" d="M175,213.2c2,.8,1.9-4,5.9-4.7,4.1-.8,5.4,3.8,7,1.9,1.4-1.5-1.7-6.9-8.3-5.4-5.5,1.4-6.5,7.5-4.6,8.2h0Z"/>
        <g>
          <path class="cls-7" d="M205.9,228c-1.9-5.4-14.7-4.7-25.9-2.1-11.2,2.5-23,7.6-22.4,13.3s12.8,16.6,28.4,12.8c15.7-3.6,21.8-18.7,19.8-24.2h0Z"/>
          <path class="cls-21" d="M186,252.2c-11.7,2.8-21.3-2.5-25.9-7.9,5.2-1.2,12-1.8,18.3-1.8s15.2.6,20.8,2.3c-3.1,3.3-7.5,6-13.2,7.4h0Z"/>
          <path class="cls-20" d="M198.6,224.2c-5.2-.6-12.2.4-18.7,1.8-6.5,1.6-13.3,3.9-17.7,6.6,5.1,3,12.1,4.7,19.8,2.9,7.9-1.8,13.3-6.4,16.4-11.2h.1Z"/>
        </g>
        <circle class="cls-12" cx="149.1" cy="216.1" r="8.1"/>
        <circle class="cls-12" cx="206.5" cy="203.3" r="8.1"/>
      </g>
    </g>
    <g>
      <path class="cls-17" d="M545.2,295.2c6.7,2.4,37.3,20.2,54.7,27.9,9.8-1.7,16.9,1.9,17.2,3.8.4,2.7-6.4-.2-7.8.3.2.2,8.5,4.7,9.8,5.5,2.9,1.9,1.9,4.7-1,4-2.6-.5-7.9-2.7-8.1-2.9.2.2,6,4.8,7.1,5.6,3.1,2.3,1.1,6.2-3.4,3.4-3-1.9-7.1-4.7-6.9-4.7,0,.2,3.2,4.1,4.3,6.3,1.7,3.5-1.3,5-4.1,1.8-2.2-2.7-9.3-9.6-9.3-9.6,0,0-1.8,6.7-4.2,5.9-3.1-1,.4-10.6.4-10.6,0,0-33.1-16.8-51.6-25.3,1.7-2.5,2.7-6.9,2.9-11.4h0Z"/>
      <path class="cls-17" d="M459.8,304.7c-6.4,3-40.7,12.1-58.4,19-5.7,8.1-13.3,10.6-14.8,9.5-2.2-1.6,4.7-4.4,5.3-5.7-.3,0-9.3,2.7-10.8,3-3.4.7-4.7-2-2.1-3.5,2.2-1.5,7.5-3.7,7.8-3.7-.3,0-7.6.8-9,1.1-3.8.6-5.2-3.6,0-4.8,3.5-.8,8.3-1.7,8.2-1.6-.1-.1-5.2-.6-7.5-1.4-3.7-1.3-2.6-4.5,1.6-4.2,3.5.4,13.4.2,13.4.2,0,0-3.5-6-1.2-7.1,2.9-1.5,7.2,7.8,7.2,7.8,0,0,35.3-11.5,54.4-18.6.6,3,3,6.8,6,10.1h-.1Z"/>
      <g>
        <path class="cls-18" d="M457.4,395.9s-5.7,85.8-8,95.6c5,3.6,11.4-3.5,11.4-3.5l9.4-90.6-12.8-1.5Z"/>
        <g>
          <g>
            <path class="cls-13" d="M457.5,496.9c2,2.3,2.2,7.8-.9,9-6,1.5-24.9,2-30.7.5-5.9-1.5.1-11.6.1-11.6l31.5,2.1Z"/>
            <path class="cls-22" d="M460.8,487.9c.5,2.7,1.4,10.6-2,12.3-6.3.3-22.6,2-31.3,1-9-2.9.8-10,6.1-11.1,5.3-1.1,16.3-5.5,16.3-5.5,0,0,4.8,2.4,10.9,3.3h0Z"/>
          </g>
          <path class="cls-13" d="M442.3,486.2c2.8-.1,6.2,2,7.1,4.2.8,2.2-2.2,1.8-3.3,1.5-1.1-.4-2.8-1.3-6.3-2.6-3.6-1.3.2-2.9,2.5-3.1h0Z"/>
          <path class="cls-13" d="M434.6,488.6c2.8-.1,6.2,2,7.1,4.2.8,2.2-1.7,1.7-2.8,1.3-1.1-.4-3.3-1.1-6.8-2.5-3.5-1.3.2-2.9,2.5-3h0Z"/>
        </g>
      </g>
      <g>
        <path class="cls-18" d="M531.1,387.9s28.9,28.3,73.2,6.5c-.8-6.1-4.3-10.1-4.3-10.1,0,0-34.8,18.3-57.9-3.3-2.3,3.3-11,6.9-11,6.9Z"/>
        <g>
          <g>
            <path class="cls-13" d="M603.9,380.8c.3-3,4.4-4.8,6.2-4.4,6.2,1.5,16.7,28.6,16.7,35s-6.9,4-6.9,4l-16-34.6Z"/>
            <path class="cls-22" d="M594.5,386.9c1.6-2.2,7.3-9.6,10.9-8.3,3.3,1.2,15.5,24.9,16.4,32.7,1.1,9.4-6.5.2-10.9-2.9-4.4-3.2-12.3-11.9-12.3-11.9,0,0,.9-4.7-4.1-9.6h0Z"/>
          </g>
          <path class="cls-13" d="M602.3,403.2c-2-2-2.7-5.9-1.7-8s2.8.3,3.3,1.4c.5,1,1,2.9,2.4,6.4,1.4,3.4-2.4,1.8-4,.2Z"/>
          <path class="cls-13" d="M609.5,409.5c-2-2-2.7-5.9-1.7-8s2.4.1,2.9,1.1,1.4,3.2,2.8,6.7c1.4,3.5-2.4,1.8-4,.2h0Z"/>
        </g>
      </g>
      <g>
        <path class="cls-18" d="M527.6,281.4s-9-2.4-14.7-4.8c0-6.1,2-12.7,2-12.7l-12.8-2.3s-1.9,9.8-2.2,13.8c-8.3,1.2-15.7,1.2-15.7,1.2,0,0-9.9,25.6,18.3,27.4,7.4.4,25.2-11,25.1-22.6h0Z"/>
        <path class="cls-6" d="M511.6,270.6l-.4,7.6s-4.7-5.8-10.5-1.1c1.3-7.7,1.8-9.7,1.8-9.7l9.1,3.2Z"/>
      </g>
      <g>
        <path class="cls-17" d="M568.5,236.2c-11,31.5-43,43.8-73.3,33.2-30.3-10.7-47.5-40.2-36.4-71.8,11-31.5,44.2-47.3,74.5-36.6,30.2,10.8,46.2,43.8,35.2,75.2h0Z"/>
        <g>
          <path class="cls-17" d="M566.2,234.4s3-6.4,11.5-4.7c8.9,1.7,8.6,14,1.9,19.2s-16.3,2.3-17.5-3.9l4.1-10.6Z"/>
          <path class="cls-4" d="M578.4,233.1c-5.2-2.7-12.8,5.7-6.8,13.2-1-7.9,2.6-9.4,5.6-4.8.7-2.3-.3-4.1-3.7-5,1.2-2.9,5-3.4,5-3.4h0Z"/>
        </g>
        <path class="cls-21" d="M509.8,225.4c-1.6.4-1.1-3.2-4.1-4.1s-4.5,2.5-5.6.9c-.9-1.3,1.9-5.2,6.9-3.5,4.1,1.5,4.4,6.3,2.9,6.7h0Z"/>
        <g>
          <path class="cls-7" d="M478.1,233.1c2.2-4.6,13.5-2.7,23.2.6,9.7,3.4,19.7,8.9,18.6,13.9s-12.9,13.5-26.4,8.8c-13.6-4.7-17.6-18.7-15.4-23.2h0Z"/>
          <path class="cls-21" d="M493.4,256.4c10.1,3.5,19.2-.3,23.6-4.5-4.6-1.5-10.4-2.7-16-3.3-6.6-.7-13.6-.8-18.6,0,2.6,3.2,6.2,6,11,7.7h0Z"/>
          <path class="cls-20" d="M484.9,230.4c4.6,0,10.8,1.4,16.5,3.4,5.6,2,11.4,4.6,15,7.5-4.8,2.1-11,3-17.8.7-6.9-2.4-11.3-6.9-13.6-11.6h-.1Z"/>
        </g>
        <path class="cls-15" d="M538.8,214.4c-5.1-3.6-8.5,2.1-4.7,3.5,4,1.5,6.9,4.1,7.5,3.9,1.4-.4,0-5.4-2.8-7.4Z"/>
        <path class="cls-15" d="M484.2,195.7c6.3.2,5.4,6.9,1.6,5.7-4.1-1.3-8-.9-8.3-1.5-.9-1.2,3.2-4.3,6.7-4.2h0Z"/>
        <path class="cls-12" d="M522.7,228.3c1,.8,4.8-3.9,8.8-2.5,5.9,2.1,4.9,8,6.4,7.7,1.2-.1,1.8-8.4-4.8-11.1-5.6-2.3-12.2,4.6-10.5,5.9h0Z"/>
        <path class="cls-12" d="M487.8,216.4c-1.3,0-1.4-6-5.5-7.4-5.9-2-8.8,3.2-9.8,2.2-.8-.9,3.7-7.7,10.6-5.9,5.8,1.5,6.9,11.1,4.6,11.1h0Z"/>
      </g>
      <g>
        <path class="cls-19" d="M452.5,314s-9.4-12.2-13-21.7c2.3-1.3,5.6-2.8,5.6-2.8,0,0,6.7,12.4,13.4,21.4-3.5,1.9-6,3.1-6,3.1Z"/>
        <path class="cls-19" d="M491,275.5c-1.1,3.9-1.6,10.8,9.6,12.9,10.8,2.1,18.1-5.2,20.5-9.3,3,1.2,6.8,2.1,6.8,2.1,0,0-10.7,16.3-29.7,13-17.8-3.1-14.7-19.4-14.7-19.4l7.5.7h0Z"/>
        <path class="cls-19" d="M567.3,300.8s-5.6,14.7-12.7,23c-2.8-1-6.6-3.7-6.6-3.7,3.5-3.5,8.4-10.6,12.6-23.2,3.3,1.6,6.7,3.9,6.7,3.9h0Z"/>
      </g>
    </g>
            </g>
            <g id="objects">
                <path id="par_baiat" style={parBaiatStyle} data-name="par baiat" class="cls-3" d="M220.9,164c11.4,13.5,15.4,27.5,15.4,27.5,0,0,15.1-16.1,7-43.2-10.7-35.6-51.2-43.5-51.2-43.5,0,0,6.7-4.6,5.3-7.3-2.2-4.6-8.7,2.7-10.7,5.3-2.1-3.7-6.3-9.9-8.7-8.7-2.1,1.1.4,5.8,2,8.7-39.9-4-86,20.7-95.6,56.2-12.5,46.3,27.1,60.7,27.1,60.7,0,0,18.5-15.3,20.5-29.5,2.3,3.3-2.9,17.4-2.9,17.4,0,0,28.6-12.5,37.9-28.4,2.7,5.2-10.7,23.8-10.7,23.8,0,0,65.4-4.8,64.5-39h0Z"/>
                <path id="pantaloni_baiat" style={pantaloniBaiatStyle} data-name="pantaloni baiat" class="cls-22" d="M220.9,374.4c-33.8-4.3-86.3-7.2-86.3-7.2-1.6,8.6.7,21.1-1.3,28.9h.1l-7.2,52.2,38.1,2.6c8.1-13.8,20.5-45.7,20.5-45.7,2.8,8.8,12.4,44.7,12.4,44.7l38-7.2c1.2-25.2-14.3-68.3-14.3-68.3h0Z"/>
                <path id="tricou_baiat" style={tricouBaiatStyle} data-name="tricou baiat" class="cls-11" d="M224.7,309.2c2.5-2.6,9.1-9.8,14.3-19.9-14.4-8.8-25.4-12.3-33-14.3.2,4-.8,16.5-18.3,18.4-18.8,2-29.7-11.5-32.7-15.8-8,4-15.1,4.7-19.2,4.7-.8,10.2-4.4,23.4-6.3,29.6,2.7.9,6.9,2,12.5,2.2-1.2,5.5-9.9,58.3-11.7,61.2,0,0,46.7,10.1,99-.5,0,0-5.2-57.7-7.6-67.9.6.2,1.7,1.2,3,2.3h0Z"/>
                <g id="par_fata" data-name="par fata">
                <path style={parFataStyle}  class="cls-5" d="M458.9,197.6c-10.8,5-18,15.9-19.5,30.6-2.4,23.8-8.2,30.2-17.2,25.5,6.6,12.2,37.4,21.8,47.7,7.2,1.2-1.7,2.7-3.4,4.4-5.2-16-14.1-23.1-35.6-15.5-58.2h.1Z"/>
                <path style={parFataStyle}  class="cls-5" d="M596.5,225.7c.6-1.1,1.2-2.2,1.7-3.4,17.1-37-4.4-83-49.2-80.8-28-26.1-77.3-9.3-86.9,35.3-1.2,5.9-1.9,11.7-1.8,17.4.5-1.2,1-2.2,1.5-3.3,6.6,3.3,39.8-9.1,62.5-24.1,12.1,29.7,35.6,57,45.5,61.4v.2c-.2.9-.4,1.8-.7,2.7h.2c.3-.2.7-.3,1-.5s.4-.2.7-.2c.4-.2.8-.2,1.2-.3.4,0,.8-.2,1.2-.2,1,0,2.2,0,3.5.2.2,0,.5,0,.7.2h.2c.2,0,.3,0,.5.2h.2q.2,0,.4.2s.2,0,.2.2c0,0,.2.2.3.2,0,0,.2,0,.2.2,0,0,.2.2.3.2,0,0,.2,0,.2.2,0,0,.2.2.3.2,0,0,.2,0,.2.2l.2.2.2.2s.2.2.2.3c0,0,0,0,.2.2.2.2.2.3.3.5v.2c0,.2.2.2.2.4v.2s0,.2.2.3v3.4c0,.7,0,1.6-.2,2.3h0v.8c0,.2,0,.3-.2.5v.2c0,.2,0,.3-.2.5h0c0,.2,0,.4-.2.6h0c0,.2-.2.4-.2.7h0c-.5,1-1.1,2-1.7,2.8-.5.7-1.1,1.2-1.7,1.7-.6.5-1.2.8-1.8,1.2-.2,0-.3.2-.4.2-.3.2-.7.3-1.1.5h-.3c-.2,0-.5.2-.8.2h-.5c-.2,0-.4,0-.7.2h-4.1c-.2,0-.4,0-.6-.2h-.4c-.2,0-.4,0-.6-.2q-.2,0-.4-.2c-.2,0-.3-.2-.6-.2s-.2-.2-.4-.2-.3-.2-.5-.3c0,0-.2-.2-.3-.2-.2,0-.3-.2-.5-.3,0,0-.2-.2-.3-.2-.2-.2-.3-.2-.4-.4l-.2-.2c-.2-.2-.2-.3-.4-.5l-.2-.2c-.2-.2-.3-.5-.5-.8,0,.2-.2.2-.2.4-.4.6-.7,1.2-1.2,1.8-.2.2-.3.6-.6.8-.5.7-.9,1.3-1.4,1.9-.2.2-.4.6-.7.8-.3.4-.7.7-1,1.2-.4.4-.7.8-1.2,1.2-.3.4-.7.7-1.1,1.1-.3.3-.7.7-1,1l-.7.7c-.2.2-.5.5-.8.7h0c-2.3,2.1-4.7,3.9-7.3,5.5,2.7-.5,5.5-1.1,8.2-1.9,1.3,5.9,2.2,11.4,2,15.8-.7,17.9,29.6,29.1,42.2,23.5-10-1.7-10.6-10.2,2-30.6,9.7-16.5,8.3-33.3-3.6-44.9l1.2.4Z"/>
                </g>
                <path id="fusta_fata" style={fustaFataStyle} data-name="fusta fata" class="cls-16" d="M462.1,355.9s45.7-7.2,69-7.8c0,0,14.1,30.1,36.2,41,0,0-14.3,59.3-117.7,49.6,0-.1,2.9-53.4,12.5-82.8h0Z"/>
                <path id="tricou_fata" style={tricouFataStyle} data-name="tricou fata" class="cls-9" d="M548.2,319.9c3.4-3.5,8.2-10.3,12.2-22.5-7-4.3-28-13.8-33-15.5-2.4,3.2-12.6,15.1-29.1,12.3-14.2-2.5-15.1-13.3-14.9-17.6-9.1,1.4-20.4,2-37.9,13.5,1.4,2.5,7.1,12.7,12.9,20.5.8-.3,1.4-.6,1.5-.7-1.1,11.1,2.3,45.9,2.3,45.9,44.8,3.8,69-7.8,69-7.8-1.4-2.6,7.1-28.8,7.9-31.1,2,.6,6.3,2.3,9.1,3h0Z"/>
            </g>
            <g id="overlays">
                <g class="cls-23">
                <path class="cls-10" d="M459,197.7c-10.8,5-18,15.9-19.5,30.7-2.4,23.8-8.2,30.2-17.2,25.5,6.6,12.2,37.4,21.8,47.7,7.2,1.2-1.7,2.7-3.4,4.4-5.2-16-14.1-23.2-35.6-15.5-58.2h.1Z"/>
                <path class="cls-10" d="M596.6,225.8h0c-1.7,1.8-3.2,6.2-13.2,12.6.3,1.4-.7,7.1-4.7,10.2-5.7,4.5-13.7,2.7-16.2-2.1-2.8,4.6-6.2,8.5-9.9,11.8,1.8,7.2,3,14.2,2.8,19.4-.7,17.9,29.6,29.2,42.2,23.5-10-1.7-10.6-10.2,2-30.7,10.2-16.3,8.8-33.2-3.1-44.7h.1Z"/>
                </g>
                <path class="cls-8" d="M550.6,409.2s-25.1-26.8-29.3-55.5l9.4-3.3s6.7,30.8,32.7,42.4c0-.1-1.9,9.1-12.8,16.4h0Z"/>
                <path class="cls-20" d="M167.5,379.4c-4.4,8.2-15.7,7.8-18.5,7.5.4-2.7.7-5.7.9-8.8-.7-.1-1.3-.1-1.8-.2-.6,8.1-9.9,65.9-11.3,71.1h1.9c.7-2.5,9.7-57.4,10.2-60.5h.3c3.5.2,15.8.4,20.3-9.1h-2,0Z"/>
                <g class="cls-24">
                <g>
                    <path class="cls-1" d="M449.9,430.9s.7.1,2,.3"/>
                    <path class="cls-2" d="M455.9,431.8c19.2,2.4,79.4,5.7,104.6-42.1"/>
                    <path class="cls-1" d="M561.3,387.9c.3-.6.6-1.2.9-1.8"/>
                </g>
                </g>
                <g class="cls-23">
                <path class="cls-10" d="M469.6,162c29.9,8.7,58.1-9.2,58.1-9.2,0,0-38.5,9.4-58.1,9.2Z"/>
                <path class="cls-10" d="M530.8,143.8s-25.2-1.7-47.6-2.4c18.6,8.3,47.6,2.4,47.6,2.4Z"/>
                <path class="cls-10" d="M534.3,155.1s11.3,31.3,40.3,42.7c-15.7-11.7-40.3-42.7-40.3-42.7Z"/>
                <path class="cls-10" d="M576.2,173.1c-18.2-13.1-39.1-27.2-39.1-27.2,0,0,19.4,22.5,39.1,27.2Z"/>
                </g>
            </g>


        </svg>
        

        </>
        


        
 
        
        


    );
}

export default Copii;