


const CodeBaiat = (props) => {

    const ChangeColor = props.ChangeColor;

    return(
        <>
        
        <code className="code-box code-baiat">
            <span className="code-class">.par-baiat</span> <span className="brackets">&#123;</span> 
            <br/>
            &nbsp;&nbsp;color: #<input type="text" onChange={(event)=>{ChangeColor('par-baiat', event.target.value)}}></input>;
            <br/>
            <span className="brackets">&#10101;</span>
            <br/>
            <br/>

            <span className="code-class">.tricou-baiat</span> <span className="brackets">&#123;</span> 
            <br/>
            &nbsp;&nbsp;color: #<input type="text" onChange={(event)=>{ChangeColor('tricou-baiat', event.target.value)}}></input>;
            <br/>
            <span className="brackets">&#10101;</span>
            <br/>
            <br/>

            <span className="code-class">.pantaloni-baiat</span> <span className="brackets">&#123;</span> 
            <br/>
            &nbsp;&nbsp;color: #<input type="text" onChange={(event)=>{ChangeColor('pantaloni-baiat', event.target.value)}}></input>;
            <br/>
            <span className="brackets">&#10101;</span>
            <br/>
        </code>
        </>
    )
}

export default CodeBaiat;